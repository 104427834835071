.top-section-gradient {
  background: linear-gradient(180deg, #010101 36.98%, #380403 100%);
}

@screen md {
  .top-section-gradient {
    background: linear-gradient(90deg, #010101 36.98%, #380403 100%);
  }
}

.buy-button {
  background: linear-gradient(
    91.39deg,
    #D32710 1.18%,
    rgba(221, 66, 45, 0.74) 49.78%,
    rgba(224, 84, 64, 0.45) 99.41%
  );
}